import React from "react";
import ReactModal from 'react-modal';

class ExampleApp extends React.Component {
  constructor () {
    super();
    this.state = {
      showModal: false
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
  render () {
    return (
      <div className="pledges">
        <a href="#" onClick={this.handleOpenModal}>👉 {this.props.message}🖱</a>
        <ReactModal 
           isOpen={this.state.showModal}
           contentLabel="What is this all for?"
           style={{
              content: {
                color: 'White',
                backgroundColor: "Orange"
              }
            }}
        ><div className="pledges">
          
          <button onClick={this.handleCloseModal}>x</button>
          <h1>What am I fundraising for?</h1>
          <h2>The Great Labyrinth!</h2>
          <iframe title="roxy da cat" width="560" height="315" src="https://www.youtube.com/embed/Pl97pv89kZM" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>
          
     

          <p>The Great Labyrinth is a project set up to build a giant <b>eff-off </b>Labyrinth out of Spekboom trees.</p>

          <p>I am referring you to their website for marketing shizzle, but Spekbooms are cool AF trees, they suck carbon out of the air!  And <i>labyrinths are so cool </i> I can't even talk about it all here without imploding. </p>


          <a href="https://www.samara.co.za/blog/five-things-didnt-know-spekboom/">5 Things You Didn't Know About Spekbooms</a>

          <p>5 Things Not To Do In A Labyrinth</p>

      

          <p>So what's not to love? </p>

          <p>Start crying, everything has been going miserably since things got weird, and the massive cool as-eff carbon sucking tree maze project is looking difficult. In fact if we don't help it might all be over. Don't stop crying yet.
          </p>

          <p>I'm going to refer you to the charity website <i>again</i> for you to read more about that.</p>


          <p>I can't find a useful online platform to set up a cool goal-based Spend 100 Quid And Get Jack To Eat Worms Live On Twitch Pledge reward-scheme ala Kickstarter so here is a website that allows that to happen:
          </p>
          <a href="https://www.givengain.com/ap/jack-fenton-raising-funds-for-the-heart-charitable-investment-trust/">I RLY DISLIKE THE IDEA OF USING YOUR STUPID WEBSITE LET ME PAY STRAIGHT TO HORSES' MOUTH</a>


Things I might do for cash:

- <b>Jump out of a plane</b> (I'll pay for the sky dive myself so this isn't just a <i>Pay For Jack's Extreme Sporting blag</i>)

- Somehow <b>do a triathlon</b> somehow live on Twitch

- Eat Worms

- Other Things

- Record <b>highly</b> embarrassing but anti-calorific <b>work-out videos</b>

<p>Calling all philanthropists and hippies etc. Let's save the thing.</p>
PLS HELP
 
 
LOVE JACK @ fenton.wtf

<p>
-----------------
</p>
Fact check:

I did due diligence and can 10000% confirm by gambling your money on my fundraising you may get a really warm feeling as it's going to a really good place so please help out.
<p><button onClick={this.handleCloseModal}>back</button></p></div>
        </ReactModal>
      </div>
    );
  }
}



export default ExampleApp;

