import React, { useEffect, useState } from 'react';
import PopUp from "./Modal";
import ExampleApp from "./Info";
import Blog from "./Blog";
import Faked from "./fake";
import Loader from 'react-loader-spinner';
import './App.css';
import img from "./FUNDr.png";
import givengain from "./givengain.png";
import faker, { fake } from  'faker';
import Card from './Card'

const useFetch = (url) => {
  const [data, setData] = useState(null);

  useEffect(() => {
    fetch(url, { method: 'POST'}).then(async res => {
      if (res.status !== 200) {
        setData('uh oh error!');
      }
      const data = await res.json();
      setData(data);
    });
  }, [setData, url]);

  return [data];
}



const App = () => {
  let [goals] = useFetch('https://u01clhpq7i.execute-api.eu-west-1.amazonaws.com/prod/goals');
  if(goals) { 
    goals = (goals[0])
  } else {
    goals = ['']
  }
  let [items] = useFetch('https://u01clhpq7i.execute-api.eu-west-1.amazonaws.com/prod/items');
  if(items) { 
  } else {
    items = ['']
  }
  let [donations] = useFetch('https://u01clhpq7i.execute-api.eu-west-1.amazonaws.com/prod/donations');
  if(donations) { 
  } else {
    donations = ['']
  }
  let [emails] = useFetch('https://u01clhpq7i.execute-api.eu-west-1.amazonaws.com/prod/update');
  if(emails) { 
  } else {
    emails = ['']
  }


  return (
    <div id ="background" style={backGround}><ExampleApp message="Tell Me More About What This Is For"/>

      <div style={loGo}><img src={img} alt="logo!"/></div>
      
{/* Targets: */}

      <div style={{display: goals.donations ? 'block' : 'none' }}>
      {goals.name === null? <div><p key="load"><Loader type="ThreeDots" color="#FFA500" height="50" width="50" /></p></div>: null}
      <div id='pledges' style={pledges} className='options'>
        {/* {Object.keys(goals).map(key => (
          <div>   */}
           <h1> {goals.donations? <div>{goals.donations + " of " }{goals.goal + "  raised" }</div>: null}  </h1>
           <h1> {goals.days? <div>{goals.days + "  days to go" }</div>: null}  </h1>
           <h1> <div><ExampleApp message="WTF" /></div></h1>
           <h1> <div><Blog message="BLOG" /></div></h1>

           
            </div>
            
          {/* // <div>
          //   {goals.donations? <div>{goals[key] + " " + key}</div>: null}
            // </div> */}
        {/* ))}
      </div> */}
      </div>

{/* The shoppe: */}

      <div id='items' style={itemsContainer} >
      <div id='pledges' style={pledges} >
      
      {items.map((item, index) => (
  
        <div key={item.item + index}>

     
        
        {/* Physical clutter: */}
        {item.content? <div>{item.bought === "false" ? <div>{item.content.physical? <div style={itemContainer} className='label-hover'>
        {item.price === null? <div><p key={item.price}><Loader type="ThreeDots" color="#FFA500" height="50" width="50" /></p></div>: null}
        {item.price? <div><p key={item.price}><h1>{item.item}</h1> £{item.price}</p><p key={item.description}>{item.description}</p><PopUp itemType={item.type} itemPrice={item.price} itemId={item.id}/>{item.type ==='repeat'? <div id="repeater"><p key={item.type}>Repeat payments only</p></div>: null}</div>: null}
        </div>:null} </div>:null} </div>:null} 
     
        
        {/* Video clutter: */}
        {item.content?  <div>{item.bought === "false" ? <div>{item.content.video? <div style={videoContainer} className='label-hover' >
        <p key={item.item}></p>
                {item.price? <div><p key={item.price}><h1>{item.item}</h1>{item.item} £{item.price}</p><p key={item.description}>{item.description}
                 {/* YouTube: */}
                  <div style={{display: item.content.video.type === "youtube" ? 'block' : 'none' }}>
                    <iframe width="320" src={"https://www.youtube.com/embed/" + item.content.video.slug}></iframe>
                  </div>
                  {/* Twitch: */}
                  <div style={{display: item.content.video.type === "twitch" ? 'block' : 'none' }}>
                    <iframe
                        src={"https://player.twitch.tv/?video=" + item.content.video.slug + "&parent=jackfenton.com&autoplay=false"}
                        width="320"
                        frameborder="0"
                        scrolling="no"
                        allowfullscreen="true">
                    </iframe>
                  </div>
                </p><PopUp itemType={item.type} itemPrice={item.price} itemId={item.id}/>{item.type === 'repeat'? <div id="repeater">
                <p key={item.type}>Repeat payments only</p>

                </div>: null}</div>: null}
            </div>:null} </div>:null} </div>:null} 
                    {/* SOLD clutter: */}
        {item.bought? <div>{item.bought === "true" ? <div style={soldContainer} className='sold-hover'>
        <p key={item.item}>{item.item}</p>
        {item.price? <div><p key={item.price}></p><p key={item.description}>{item.description}</p><b>SOLD</b></div>: null}
        </div>:null} </div>:null} 
        </div>

        
      ))}

      
      </div>
      </div>



      <div id='donations' style={itemsContainer}  >
      <ExampleApp message="Tell Me More About What This Is For"/>
      {donations.map((donation, index) => (

        <div style={itemContainer} key={donation.donation + index}>
          <div style={{backgroundColor: faker.commerce.color(), WebkitTextFillColor: faker.commerce.color(), WebkitTextStroke: "0px " + faker.commerce.color(), padding: "10px"}}>
          {donation.name === null? <div><p key={donation.donation}><Loader type="ThreeDots" color="#FFA500" height="50" width="50" /></p></div>: null}
          {donation.name != "JackFenton" ? <div ><Faked name={donation.name} /></div>: null}
          {donation.name === "JackFenton" ? <div ><Faked name="ME" /></div>: null}
           </div>
        </div>
        
      ))}
               <div style={{textAlign: "center"}}> What's that all about? <a href="https://www.givengain.com/ap/jack-fenton-raising-funds-for-the-heart-charitable-investment-trust" target="_blank" ><img src={givengain} alt="givengain"/></a> 
</div>
      </div>
      </div>
  );

}



const backGround = {
  color: "white",
  backgroundColor: "Orange",
  width: "100%",
  fontSize: "calc(6px + 1vmin)",
  fontFamily: "Helvetica",
};

const itemsContainer = {
  color: "white",
  margin: "auto",
  backgroundColor: "Orange",

};

const itemContainer = {
  margin: "auto",
  backgroundColor: "Green",
  padding: "20px",

};

const soldContainer = {
  padding: "7px",
  margin: "auto",
  backgroundColor: "Grey",
};

const videoContainer = {
  color: "black",
  padding: "20px",
  margin: "auto",
  backgroundColor: "Yellow",
};


const pledges = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
  gridGap: "1px",
  boxSizing: "border-box",
  textAlign: "justify",
  textJustify: "inter-word",
	fontSize: "15px",
	fontfamily: "Helvetica"
  
}

const loGo = {
  color: "white",
  backgroundColor: "Orange",
  width: "100%",
  fontSize: "calc(10px + 2vmin)"
}

export default App;