import React from "react";
import {Helmet} from "react-helmet";

class Card extends React.Component {
  render () {
    return (
        <div className="application">
            <Helmet>

            </Helmet>
            ...
        </div>
    );
  }
};


export default Card;