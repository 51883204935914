import React from "react";
import ReactModal from 'react-modal';
import givengain from "./givengain.png";
import Loader from 'react-loader-spinner';
import ReactTextCollapse from 'react-text-collapse'
import blog1 from "./blog1.png";


const TEXT_COLLAPSE_OPTIONS = {
  collapseText: '⬇️',
  expandText: 'show less',
  minHeight: 60,
  maxHeight: 300,
  textStyle: {
    color: 'blue',
    fontSize: '15px'
  }
}

class Members extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
        postId: null
    };
}

  componentDidMount() {
    // Simple POST request with a JSON body using fetch
    const requestOptions = {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({ })
    };
    fetch('https://u01clhpq7i.execute-api.eu-west-1.amazonaws.com/prod/blog', requestOptions)
        .then(response => response.json())
        .then(data => this.setState({ postId: data }));
}
  
  render () {
    const { postId } = this.state;
    return (
      <div className="pledges"> Secret Blogs: (Click the ⬇️ emojis )
         {postId? <div>
            {postId.map((item, index) => (
            
            <div key={item.number + index}>
               <div style={itemContainer} className='label-hover'>
            <ReactTextCollapse options={TEXT_COLLAPSE_OPTIONS}>
            <p>
       {item.title}</p>
       <p> {item.content}
            </p>
            {item.image? <p><img src={blog1}></img></p>: null}  
          </ReactTextCollapse>
          </div>
          </div>
            
          ))}
  
  </div>: null}  

        
      </div>
    );
  }
}

const pledges = {
  display: "grid",
  gridTemplateColumns: "repeat(auto-fill, minmax(350px, 1fr))",
  gridGap: "1px",
  boxSizing: "border-box",
  textAlign: "justify",
  textJustify: "inter-word",
	fontSize: "15px",
	fontfamily: "Helvetica"
  
}

const itemContainer = {
  margin: "auto",
  backgroundColor: "Green",
  padding: "20px",

};

export default Members;

