import React from "react";
import ReactModal from 'react-modal';
import givengain from "./givengain.png";
import Bobal from "./Bobal";
import Members from "./Members";

class Blog extends React.Component {
  constructor () {
    super();
    this.state = {
      showModal: false
    };
    
    this.handleOpenModal = this.handleOpenModal.bind(this);
    this.handleCloseModal = this.handleCloseModal.bind(this);
  }
  
  handleOpenModal () {
    this.setState({ showModal: true });
  }
  
  handleCloseModal () {
    this.setState({ showModal: false });
  }
  
  render () {
    return (
      <div className="pledges">
        <a href="#" onClick={this.handleOpenModal}>{this.props.message}🖊️</a>
        <ReactModal 
           isOpen={this.state.showModal}
           contentLabel="What is this all for?"
           style={{
              content: {
                color: 'White',
                backgroundColor: "Pink"
              }
            }}
        ><div className="pledges">
          

          <button onClick={this.handleCloseModal}>x</button>
          <div style={{textAlign: "center"}}> <b><p>If you ain't donated:</p><p>Click this logo for updates: </p><a href="https://www.givengain.com/ap/jack-fenton-raising-funds-for-the-heart-charitable-investment-trust/#updates" target="_blank" ><img src={givengain} alt="givengain"/></a> 
<p><p>If you ave donated:</p></p></b>
<Bobal itemType="blogo"/>
</div>
<p><button onClick={this.handleCloseModal}>back</button></p></div>
        </ReactModal>
      </div>
    );
  }
}



export default Blog;

