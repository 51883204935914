

import React from "react";
import Post from "./Post";
import givengain from "./givengain.png";

class Modal extends React.Component {
    render() {
        if(!this.props.show){
            return null;
        }
    
      return <div>
       <div id="buyers">
       <p> YAY! </p>You want to help fundraise!? <p><b>Click here:</b></p> <a href="https://www.givengain.com/ap/jack-fenton-raising-funds-for-the-heart-charitable-investment-trust" target="_blank" ><img src={givengain} alt="givengain"/></a> 
         Donate: £{this.props.itemPrice}, or more. 
       <p>When you come back here enter the donation ID you get in your email. </p>
        </div>
        <div>
            
        <Post itemType={this.props.itemType[0]} itemPrice={this.props.itemPrice} itemId={this.props.itemId}/>
        
          {this.props.itemType[0] === "repeat" ? <div id="repeater"><p key={this.props.itemType}>Repeat payments only</p></div>: null}

        </div>

    </div>;
    }
  }

class PopUp extends React.Component {
    state = {
        show: false
      };
      showModal = e => {
        this.setState({
          show: true
        });
      };
  render() {
    return (
      
      <div className="Modal">
        <div class="form-style-6">
        <button class="form-style-6" onClick={e => {
              this.showModal();
         }}
          > buy </button>
            </div>
        <Modal show={this.state.show} itemType={this.props.itemType} itemId={this.props.itemId} itemPrice={this.props.itemPrice}/>
      </div>
    );
  }
}
export default PopUp;

