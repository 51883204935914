import React from "react";
import './App.css';
import img from "./FUNDr.png";
import gif from "./source.gif";

const refreshPage = ()=>{
    window.location.reload();
 }

class Post extends React.Component{
    

constructor(){
super();

this.state={ name:'', email:'' }
}
handleChange = event =>{
this.setState({ [event.target.name]:event.target.value })
}

handleSubmit = event =>{
event.preventDefault();
const url ="https://u01clhpq7i.execute-api.eu-west-1.amazonaws.com/prod/buy"
const data = { name:this.state.name, email:this.state.email, confirmation:this.state.confirmation, price:this.props.itemPrice , id:this.props.itemId, type:this.props.itemType}
fetch(url, { method: 'POST', // or 'PUT'
body: JSON.stringify(JSON.stringify(data)), // data can be `string` or {object}!
headers:{ 'Content-Type': 'application/json' } })
.then(res => res.json())
.catch(error => console.error('Error:', error))
.then(response => {
        console.log('Success:', response);
        this.setState({ response: response });

        ;
    });
}



render(){
return(
    <div class="form-style-6">
<form onSubmit={this.handleSubmit}>
<input type="text" name="name" placeholder="Your Name" onChange={this.handleChange} />

<input type="text" name="email" placeholder="em@il" onChange={this.handleChange} />

<input type="text" name="confirmation" placeholder="donation ID" onChange={this.handleChange} />

<input type="submit" value="confirm" class="blueButton"/> 
</form> 


{this.state.response? <p>{this.state.response.message? <p>{this.state.response.message}</p>: null}</p>: null}
{this.state.response? <p>{this.state.response.message === "Correct!"? <div id ="overLay" style={overLay}><img src={img} alt="Click Me"onClick={refreshPage} style={{"pointer-events": "all"}} /><h1>COOL! CLICK TO REFRESH!</h1><img src={gif} alt="Click Me"onClick={refreshPage} style={{"pointer-events": "all"}} /></div>: null}</p>: null}



</div>
)
}
}

const overLay = {
    padding: "10px",
    margin: "auto",
    backgroundColor: "Yellow",
    height: "100%",
    width: "100%",
    position: "fixed",
    zIndex: "1",
    top: "0",
    left: "0",
    overflowX: "hidden",
    }

export default Post;